import React, { Component } from 'react';
//import ScheduleFile from'./ScheduleByDay.txt';

class Schedule extends Component{
    
    render(){
        // const readline = require('readline');
        // const fs = require('fs');

        // const readInterface = readline.createInterface({
        //     input: fs.createReadStream('/path/to/file'),
        //     output: process.stdout,
        //     console: false
        // });

        // readInterface.on('line', function(line) {
        //     console.log(line);
        // });

        return (
            <div id = "ScheduleSection" >
                <div id ="ScheduleGridContainer">
                    <div className ="Schedule-DayContainer">
                        <div className="Schedule-Day-Title">
                            Friday
                        </div>
                        <div className="Schedule-Day-ItemContainer">
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    5:30 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Check-In Starts
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    7:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Opening Ceremony 
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    8:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Team Building Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    8:30 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Idea Creation Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    9:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Hacking starts
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    10:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    K Pham Neural Networks Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    11:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    R Loui Linux Workshop
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className ="Schedule-DayContainer">
                        <div className="Schedule-Day-Title">
                            Saturday
                        </div>
                        <div className="Schedule-Day-ItemContainer">
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    1:45 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Mini Tournament: Squabble
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    8:00 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Day 2 Start
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    10:00 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    M Kuznetsov Frontend Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    11:00 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Z Baldwin Raspberry Pi Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    12:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    xLab Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    1:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    MLH Event: !light
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    2:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Sponsor Networking
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    5:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    K Markey Machine Learning with R Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    6:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Security 101 Workshop
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    9:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    MLH Event: Werewolf
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className ="Schedule-DayContainer">
                        <div className="Schedule-Day-Title">
                            Sunday
                        </div>
                        <div className="Schedule-Day-ItemContainer">
                        <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    1:00 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Mini Tournament 2 : Hacker's Choice
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    8:00 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Hacking ends
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    9:00 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Project Submission Deadline
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    10:15 AM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Judging Starts
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    12:00 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Judging Ends
                                </div>
                            </div>
                            <div className="Schedule-Day-Item">
                                <div className="Schedule-Day-Item-Time">
                                    12:30 PM
                                </div>
                                <div className="Schedule-Day-Item-EventName">
                                    Closing Ceremony
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Schedule;