import React, { Component } from 'react';
import xlablogo from '../Assets/SponsorLogos/xlab.webp'
import elitelogo from '../Assets/SponsorLogos/elitedesignerhomesinc.jpg'
import rockwelllogo from '../Assets/SponsorLogos/RockwellLogo.png'
import stickerMuleLogo from '../Assets/SponsorLogos/stickerMuleLogo.svg'
class SponsorshipSection extends Component{
    render(){
        return (
            <div id = "SponsorshipSection" >
                
                <div id = "sponsorshipHeader">
                    Make connections <br/>with our incredible sponsors...
                </div>
                <div id = "SponsorGrid" class="flex-grid">
                    <div className="SponsorGrid-Item topTierSponsor"><div><a href="https://weatherhead.case.edu/centers/xlab/"> <img src={xlablogo}></img></a></div></div>
                    <div className="SponsorGrid-Item topTierSponsor"><div><a href="https://www.rockwellautomation.com/en-us.html"> <img src={rockwelllogo}></img></a></div></div>
                    <div className="SponsorGrid-Item"><div><a href="https://www.elitedesignerhomesinc.com/"> <img src={elitelogo}></img></a></div></div>
                    
                </div>
            </div>
        )
    }
}

export default SponsorshipSection;
