import React, { Component } from 'react';
import HeaderSection from "./components/HeaderSection";
import SponsorshipSection from "./components/SponsorshipSection";
import FAQ from "./components/FAQ";
import Schedule from "./components/Schedule";
import TracksSection from "./components/TracksSection";
import DevpostButton from "./components/DevpostButton";
import Navbar from "./components/Navbar";
import Signup from "./components/Signup"
//import headerBackground from "./Assets/backgrounds/Header_Section_Background.svg";
import './App.css';
import {Countdown,About} from './components';
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom';


class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      HackDate: 'Mar 18, 2020 19:00:00'
    }
  }

  render() {
    return (
      <Router>
        <root>
        <Navbar />
        {<DevpostButton/>
  } 
        
        {
          /*
          <div class="parallax">
          <div class="parallax__layer parallax__layer--far"></div>
          <div class="parallax__layer parallax__layer--close"></div>
          */
        }
              <div className = "App" >
                {/*<Countdown HackDate = {this.state.HackDate} />*/}
                <div id="header">
                  <Switch>
                    <Route key="home" path="/" exact strict render={() => (
                        <HeaderSection key={Math.random()}  signUpConfirmation={false}/>   
                        )}/>
                    <Route key="finishSignUp" path="/confirm" render={({match}) => (
                        <HeaderSection key={Math.random()} signUpConfirmation={true}/>   
                        )}/>
                  </Switch>
                </div>
                <div id="about"/>
                <About/>
                <div id="faq">
                </div>
                <FAQ/>
                <div id="schedule">
                </div>

                <Schedule/>
                <div id="sponsors">
                </div>
                <SponsorshipSection/>
              </div>

              {/*<!-- The core Firebase JS SDK is always required and must be listed first -->*/}
              <script src="/__/firebase/7.6.2/firebase-app.js"></script>

              {/*<!-- TODO: Add SDKs for Firebase products that you want to use. https://firebase.google.com/docs/web/setup#available-libraries -->*/}
              <script src="/__/firebase/7.6.2/firebase-analytics.js"></script>

              {/*<!-- Initialize Firebase -->*/}
              <script src="/__/firebase/init.js"></script>


              {
              //</div>
            }

            </root>
          </Router>

        
      
    );
  }
}

export default App;
